export const ITEMS_MENU = [
  {
    name: 'Registro de horas',
    path: '/admin/register-schedule',
    icon: 'mdi-calendar-clock',
    permissions: ['m:schedules'],
  },
  {
    name: 'Registros de horas',
    path: '/admin/registers-schedules-users',
    icon: 'mdi-timetable',
    permissions: ['m:schedules-admin'],
  },
  {
    name: 'Actividades',
    path: '/admin/activities',
    icon: 'mdi-format-list-checks',
    permissions: ['m:activities'],
  },
  {
    name: 'Proyectos',
    path: '/admin/projects',
    icon: 'mdi-briefcase',
    permissions: ['m:projects'],
  },
  {
    name: 'Usuarios',
    path: '/admin/users',
    icon: 'mdi-account-supervisor',
    permissions: ['m:users', 'r:roles'],
  },
];
